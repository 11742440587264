import { render, staticRenderFns } from "./PscDashboardGeneral.vue?vue&type=template&id=7c91fca0&scoped=true&"
import script from "./PscDashboardGeneral.vue?vue&type=script&lang=js&"
export * from "./PscDashboardGeneral.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c91fca0",
  null
  
)

export default component.exports