
<template>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3 text-center">General</h4>
    </div>
    <div class="col-6 mt-4">
      <h4>REPORT STATUS</h4>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
          <tr>
            <th>REPORT STATUS</th>
            <th>COUNT</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reportStatus in pscReportStatusCount">
            <td class="align-middle font-weight-bolder">{{reportStatus.name}}</td>
            <td class="align-middle font-weight-bolder">{{reportStatus.report_status_count}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="col-6 mt-4">
      <h4>BOUNTY</h4>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
        <tr>
          <th>YEAR</th>
          <th>AMOUNT</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bounty in pscBounties">
          <td class="align-middle font-weight-bolder">{{bounty.inspection_year}} TOTAL BOUNTY =</td>
          <td class="align-middle font-weight-bolder">USD ${{bounty.total_bounty}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "PscDashboardGeneral",
  data(){
    return {

    }
  },
  computed:{
    ...mapGetters(['pscReportStatusCount','pscBounties'])
  },
  created() {
    this.getPscReportStatusCount();
    this.getPscBounties();
  },
  methods:{
    ...mapActions(['getPscReportStatusCount','getPscBounties'])
  }
}
</script>

